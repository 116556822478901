import Image from "next/legacy/image";
import Link from 'next/link';
import { useRouter } from "next/router";
import { BackgroundColor, generateMainPageCategoryImage } from "utils/image.utils";

export default function HomepageCategoriesV2() {
    const router = useRouter();


    return (
        <section className="bg-white">
            <div className="mx-auto 2xl:mt-6 2xl:mb-2 flex lgx:flex-col max-w-[1224px] px-4 mt-3 lg:px-4">
                <div className="flex flex-1 relative">
                    <div className="w-1/2 mx-2">
                        <Link
                            prefetch={false}
                            href="/shop/prescription-glasses"
                            className="text-brand-black100 transition-colors duration-300 hover:text-brand-orange">

                            <Image
                                layout="responsive"
                                objectFit="contain"
                                width={576}
                                height={640}
                                src={generateMainPageCategoryImage('prescription-glasses', router.locale, BackgroundColor.White)}
                                alt="Prescription Glasses"
                                className="rounded-[8px]"
                                priority={true}
                            />
                            <p className="mx-4 mt-2 mb-4 text-center font-semibold lg:my-2">
                                Prescription Glasses
                            </p>
                        </Link>
                    </div>
                    <div className="w-1/2 mx-2">
                        <Link
                            prefetch={false}
                            href="/shop/prescription-sunglasses"
                            className="text-brand-black100 transition-colors duration-300 hover:text-brand-orange">

                            <Image
                                layout="responsive"
                                objectFit="contain"
                                width={576}
                                height={640}
                                src={generateMainPageCategoryImage('prescription-sunglasses', router.locale, BackgroundColor.White)}
                                alt="Prescription Sunglasses"
                                priority={true}
                            />
                            <p className="my-2 mx-4 text-center font-semibold">
                                Prescription Sunglasses
                            </p>

                        </Link>
                    </div>
                </div>
                <div className="flex flex-1">
                    <div className="w-1/2 mx-2">
                        {(router.locale == 'nz' || router.locale == 'international') && (
                            <Link
                                prefetch={false}
                                href="/shop/accessories"
                                className="text-brand-black100 transition-colors duration-300 hover:text-brand-orange">

                                <Image
                                    sizes="100vw"
                                    style={{ width: '100%', height: 'auto' }}
                                    layout="responsive"
                                    objectFit="contain"
                                    width={576}
                                    height={640}
                                    src={generateMainPageCategoryImage('accesories', router.locale, BackgroundColor.White)}
                                    alt="Accessories"
                                    priority={true}
                                />
                                <p className="my-2 mx-4 text-center font-semibold">
                                    Accessories
                                </p>

                            </Link>
                        )}
                        {router.locale != 'nz' && router.locale != 'international' && <Link
                            prefetch={false}
                            href="/shop/contact-lenses"
                            className="text-brand-black100 transition-colors duration-300 hover:text-brand-orange">

                            <Image
                                sizes="100vw"
                                style={{ width: '100%', height: 'auto' }}
                                layout="responsive"
                                objectFit="contain"
                                width={576}
                                height={640}
                                src={generateMainPageCategoryImage('contact-lenses', router.locale, BackgroundColor.White)}
                                alt="Accessories"
                                priority={true}
                            />
                            <p className="my-2 mx-4 text-center font-semibold">
                                Contact Lenses
                            </p>

                        </Link>}
                    </div>
                    <div className="w-1/2 mx-2">
                        <Link
                            prefetch={false}
                            href="/shop/frames"
                            className="text-brand-black100 transition-colors duration-300 hover:text-brand-orange">

                            <Image
                                sizes="100vw"
                                style={{ width: '100%', height: 'auto' }}
                                layout="responsive"
                                objectFit="contain"
                                width={576}
                                height={640}
                                src={generateMainPageCategoryImage('frames', router.locale, BackgroundColor.White)}
                                alt="Frames"
                                priority={true}
                            />
                            <p className="my-2 mx-4 text-center font-semibold">Frames</p>

                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
