import { DEFAULT_MIN_MOBILE_VIEWPORT_WIDTH, DEFAULT_MIN_VIEWPORT_WIDTH, DeviceType } from 'constants/viewport.constants';
import { useEffect, useState } from 'react';

function useDeviceType(): DeviceType {
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    const handler = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set size at the first client-side load
    handler();

    window.addEventListener('resize', handler);
    window.addEventListener('orientationchange', handler);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
		isMobile: windowWidth <= DEFAULT_MIN_VIEWPORT_WIDTH,
		isTablet: windowWidth >= DEFAULT_MIN_MOBILE_VIEWPORT_WIDTH && windowWidth <= DEFAULT_MIN_VIEWPORT_WIDTH,
		isDesktop: windowWidth >= DEFAULT_MIN_VIEWPORT_WIDTH
	};
}

export default useDeviceType;
